<template>
  <el-dialog :title="editType=='add' ? '新增商品分类':editType == 'show' ?'查看商品分类':editType=='addChild'?'新增下级分类': '编辑分类信息'" :visible.sync="value" :close-on-click-modal="false" :before-close="dialogClose" width="420px">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row>

        <el-col :span="22" v-if="editType=='add' || editType =='addChild'">
          <el-form-item label="上级分类" style="text-align:left;" >
            {{form.parentId==''?'一级分类':form.parentTypeName}}
          </el-form-item>
        </el-col>

        <el-col :span="22">
          <el-form-item label="名称" prop="name">
            <el-input placeholder="最长20位" v-model="form.name" maxlength="20" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="22">
          <el-form-item label="排序" prop="sort">
            <el-input placeholder="请输入排序" v-model="form.sort" maxlength="20" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>


        <el-col :span="22">
          <el-form-item label="编号" >
            <el-input placeholder="最长20位" v-model="form.code" maxlength="20" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>
        
        
        <el-col :span="22">
          <el-form-item label="备注" prop="orgDate">
           <el-input type='textarea' placeholder="最长200位" v-model="form.remark" maxlength="200" :disabled="editShow"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-button @click="dialogClose">关闭</el-button>
          <el-button type="primary" v-debounce="onSubmit" v-if="!editShow">保存</el-button>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</template>
<script>

import { addGoodsType, updateGoodsType } from '@/api/index'
export default {
  name: 'BrandForm',
  components: {  },
  props: ['value', 'editRow', 'editType'],
  data() {
    return {
      form: {
        id:'',
        name: '',//名称
        code:'',
        remark: '', // 备注
        parentTypeName: '', // 上级分类
        parentId:'',
        sort:''
      },
      rules: {
        name: [
          { required: true, message: '名称不可为空', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '排序不能为空', trigger: 'blur' }
        ],
      },

    }
  },
  computed: {
    editShow() {
      return this.editType == 'show'
    }
  },
  created() {
    if (this.editType != 'add') {
      Object.assign(this.form, this.editRow)
    }
  },
  methods: {
    dialogClose(type) {
      if (type == 1) {
        this.$emit('success')
      }
      this.$emit('input', false)
    },
    onSubmit() {
      if (!this.$validate('form')) return
      let form = JSON.parse(JSON.stringify(this.form));
      let success = response => {
        if (response.code == 200) {
          this.$message({
            message: response.msg,
            type: 'success'
          });
          this.dialogClose(1);
        } else {
          this.$message.error({
            message: response.msg || response.error_msg,
            duration: 1500
          });
        }
      }
      if (this.editType == 'edit') {
        updateGoodsType(form).then(success)
      } else {
        addGoodsType(form).then(success)
      }
    }
  },

}
</script>